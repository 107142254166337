import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取服务费规则配置
 * @param {Object} data
 * @returns
 */
export function list (data) {
  return request.post('/ooh-product/serviceItemPrices/list', qs.stringify(data))
}

/**
 * 获取当前项目下常规媒体的可用服务项
 * @param {Object} data
 * @returns
 */
export function commonSkuServiceFeeList (data) {
  return request.post('/ooh-product/serviceItemPrices/commonSkuServiceFeeList', qs.stringify(data))
}

/**
 * 新增服务费项目价格
 * @param {Object} data
 * @returns
 */
export function addServicePrice (data) {
  return request.post('/ooh-product/serviceItemPrices/add', qs.stringify(data))
}

/**
 * 编辑服务费项目价格
 * @param {Object} data
 * @returns
 */
export function editServicePrice (data) {
  return request.post('/ooh-product/serviceItemPrices/edit', qs.stringify(data))
}

/**
 * 删除服务费规则配置
 * @param {Object} data
 * @returns
 */
export function delServicePrice (data) {
  return request.post('/ooh-product/serviceItemPrices/delete', qs.stringify(data))
}

/**
 * 更改服务费规则状态
 * @param {Object} data
 * @returns
 */
export function changeEnabled (data) {
  return request.post('/ooh-product/serviceItemPrices/changeEnabled', qs.stringify(data))
}
